<template>
  <section class="pa-3">
    <modal-loading :is-loading="saving" message="表示順を保存しています..." />
    <modal-confirmation
      :state-modal="isModal"
      title="表示順を保存してもよろしいですか？"
      color="primary"
      label="保存する"
      @hideModal="toggleModal"
      @apply="save"
    />
    <div class="d-flex align-start flex-wrap mb-10">
      <h2 class="mb-6 mr-8">ページ一覧</h2>
      <v-btn
        large
        dark
        rounded
        color="#2196F3"
        :to="{ name: 'pages_create' }"
        class="mr-5"
      >
        <v-icon dark class="mr-3"> mdi-dock-window </v-icon>
        新規登録
      </v-btn>
    </div>

    <div class="d-flex justify-space-between flex-wrap">
      <div class="d-flex">
        <v-switch
          v-model="sortableState"
          :disabled="
            pages.length === 0 ||
            ($route.query.words && $route.query.words.length > 0) ||
            ($route.query.order && $route.query.order.length > 0)
          "
          label="表示順の編集"
        />
        <v-btn
          :disabled="
            !sortableState ||
            pages.length === 0 ||
            ($route.query.words && $route.query.words.length > 0) ||
            ($route.query.order && $route.query.order.length > 0)
          "
          class="mt-3 ml-4 grey lighten-3"
          @click="toggleModal"
        >
          保存
        </v-btn>
      </div>

      <div class="d-flex col-5 pa-0">
        <v-text-field
          :value="params.words"
          type="search"
          outlined
          dense
          prepend-inner-icon="mdi-magnify"
          height="44px"
          @change="
            (value) => {
              params.words = value;
            }
          "
        />
      </div>
    </div>

    <section v-if="pages.length !== 0">
      <v-data-table
        :headers="headers"
        :items="pages"
        :page.sync="params.page"
        :items-per-page="perPage"
        :search="params.words"
        :custom-filter="search"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        no-results-text="データがありません。"
        hide-default-footer
        @page-count="pageCount = $event"
        @update:sort-desc="updateSort($event)"
      >
        <template v-slot:item.id>
          <v-icon v-if="sortableState" color="#888"> mdi-drag </v-icon>
        </template>
        <template v-slot:item.order="{ item }">
          {{ item.order }}
        </template>
        <template v-slot:item.title="{ item }">
          <router-link
            v-if="!sortableState"
            :to="{ name: 'pages_edit', params: { id: item.id, from: params } }"
          >
            {{ item.title }}
          </router-link>
          <div v-else>
            {{ item.title }}
          </div>
        </template>
        <template v-slot:item.slug="{ item }">
          {{ item.slug }}
        </template>
        <template v-slot:item.status="{ item }">
          {{ formatStatus(item.status) }}
        </template>
      </v-data-table>
      <v-pagination v-model="params.page" :length="pageCount" />
    </section>

    <!-- pagesがない場合、pagesがないページを指定した場合、ロード中用 -->
    <section v-if="pages.length === 0 || pageLength < params.page">
      <v-data-table
        :headers="headers"
        :items="[]"
        :loading="loading"
        :loading-text="'ロード中...'"
        :no-data-text="'データがありません。'"
        hide-default-footer
      />
    </section>
  </section>
</template>

<script>
import { getData } from "../../axios";
import Sortable from "sortablejs";
import ModalConfirmation from "../../components/ModalConfirmation.vue";
import ModalLoading from "../../components/ModalLoading.vue";
import store from "@/store";

export default {
  name: "Pages",

  components: {
    ModalConfirmation,
    ModalLoading,
  },

  data() {
    return {
      loading: true,
      params: {
        page: 1,
        words: "",
        order: "",
      },
      sortBy: [],
      sortDesc: [],
      pageCount: 0,
      headers: [
        { text: "", align: "left", sortable: false, value: "id", width: "5%" },
        { text: "表示順", align: "left", sortable: true, value: "order" },
        { text: "タイトル", align: "left", sortable: false, value: "title" },
        { text: "URL用文字列", align: "left", sortable: false, value: "slug" },
        { text: "ステータス", align: "left", sortable: false, value: "status" },
      ],
      pages: [],
      sortablejs: null,
      sortableState: false,
      isModal: false,
      saving: false,
      moveConfirm: false,
    };
  },

  computed: {
    // pageCountと別にpageの長さを計算
    pageLength() {
      return Math.ceil(this.pages.length / this.perPage);
    },
    perPage() {
      // 初期値: 10
      if (this.$store.getters["auth/client"].setting) {
        return Number(this.$store.getters["auth/client"].setting.perPage) || 10;
      } else {
        return 10;
      }
    },
  },

  watch: {
    $route(newValue, oldValue) {
      if (this.$route.fullPath === "/pages") {
        this.params.page = 1;
        this.params.order = "";
        this.params.words = "";
      } else if (
        (this.$route.query.words && this.$route.query.words.length > 0) ||
        (this.$route.query.order && this.$route.query.order.length > 0) ||
        Number(newValue.query.page) !== Number(oldValue.query.page)
      ) {
        this.params.page = Number(newValue.query.page);
        this.sortableState = false;
      }
    },
    sortableState() {
      this.$el
        .querySelector(".v-data-table tbody")
        .classList.toggle("sortable");
      this.sortablejs &&
        this.sortablejs.option("disabled", !this.sortableState);
    },
    params: {
      handler() {
        if (this.paramsQueryDifferent()) {
          this.routerPush();
          window.document.documentElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      },
      deep: true,
    },
  },

  mounted() {
    const table = this.$el.querySelector(".v-data-table tbody");
    const self = this;
    this.sortablejs = Sortable.create(table, {
      onEnd({ newIndex, oldIndex }) {
        self.moveConfirm = true;
        const rowSelected = self.pages.splice(oldIndex, 1)[0];
        self.pages.splice(newIndex, 0, rowSelected);
        self.pages.forEach((page, i) => {
          page.order = i + 1;
        });
      },
    });
    this.sortablejs && this.sortablejs.option("disabled", !this.sortableState);
  },

  created() {
    if (this.$route.query.page) {
      this.params.page = Number(this.$route.query.page);
    }
    if (this.$route.query.words) {
      this.params.words = this.$route.query.words;
    }
    if (this.$route.query.order) {
      this.params.order = this.$route.query.order;
      this.sortBy[0] = "order";
      this.sortDesc[0] = this.$route.query.order === "desc" ? true : false;
    }
    this.getPages();
  },

  beforeRouteLeave(to, from, next) {
    if (this.moveConfirm) {
      const answer = window.confirm(
        "このページから移動すると表示順の編集内容が破棄されます。"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  methods: {
    updateSort(event) {
      if (event.length === 0) {
        this.params.order = "";
      } else {
        this.params.order = event[0] ? "desc" : "asc";
      }
    },
    routerPush() {
      const params = Object.assign({}, this.params);
      // 空文字のクエリーを無くす：例）words=''
      Object.keys(params).forEach((key) => {
        if (params[key] === "") delete params[key];
      });
      this.$router.push({ query: params });
    },
    paramsQueryDifferent() {
      // paramsとqueryで違う項目がある場合はtrue
      // query.xxxがundefinedの場合は空文字で比較
      return Boolean(
        Number(this.params.page) !== Number(this.$route.query.page) ||
          this.params.words !== (this.$route.query.words || "") ||
          this.params.order !== (this.$route.query.order || "")
      );
    },
    getPages() {
      this.loading = true;
      getData("pages")
        .then((res) => {
          this.pages = res.data;
          this.loading = false;
          if (this.saving) {
            this.saving = false;
            this.setSuccessMessage();
          }
        })
        .catch(() => {
          this.loading = false;
          this.saving = false;
        });
    },
    search(_, search, item) {
      const regList = search.split(/\s+/).map((str) => new RegExp(str));
      return regList.every((reg) => {
        return Object.keys(item).some((key) => reg.test(item[key]));
      });
    },
    toggleModal() {
      this.isModal = !this.isModal;
    },
    save() {
      this.isModal = false;
      this.saving = true;

      const sequenceList = {
        sequences: this.pages.map((p) => {
          return {
            id: p.id,
            sequence: p.order,
          }
        }),
      }

      fetch (`${process.env.VUE_APP_API_URL}/api/admin/pages/sequence`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters['auth/token']}`,
        },
        body: JSON.stringify(sequenceList),
      }).catch((e) => {
        this.saving = false
        throw e
      }).then((res) => {
        if (!res.ok) {
          this.saving = false
          return
        }

        this.sortableState = false
        this.moveConfirm = false
        this.getPages()
      })
    },
    setSuccessMessage() {
      this.$store.dispatch("snackbar/setSnackbar", {
        message: "ページの表示順を編集しました。",
        color: "success",
        timeout: 2000,
      });
    },
    formatStatus(value) {
      if (!value) return "ー";
      return value === "published" ? "公開済み" : "下書き";
    },
  },
};
</script>

<style>
tbody.sortable {
  color: #888;
  cursor: move;
}
</style>
